import { DropdownSecondary, Modal } from '@Calix-Commerce/design-system';
import { FormLabel } from '@mui/material';
import Countries from 'components/forms/address/Countries.ts';
import { useState } from 'react';
import { ContentContainer } from './styledComponents';

const UPDATE_SHIP_TO_COUNTRY_MESSAGE =
  'In order to ensure we show you relevant products, please specify the country you wish to quote for.';

type UpdateShipToCountryModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirmation: (newCountry: string) => void;
  options?: string[];
  shipToCountry: string;
  enforceAction?: boolean;
};

const ModalContent = ({
  country,
  onCountryChange,
  options,
}: {
  country: string;
  onCountryChange: (newCountry: string) => void;
  options?: string[];
}) => {
  const optionsForDropdown = options?.map((option) => ({ id: option, text: option }));

  return (
    <ContentContainer>
      <p>{UPDATE_SHIP_TO_COUNTRY_MESSAGE}</p>
      <FormLabel>Ship To Country:</FormLabel>
      <DropdownSecondary
        options={optionsForDropdown?.length ? optionsForDropdown : Countries}
        attributeToBeDisplayed={'displayContent'}
        selectedOptionId={country || 'Country'}
        onSelectOption={(newSelectedCountry) => onCountryChange(newSelectedCountry.id)}
      />
    </ContentContainer>
  );
};

const UpdateShipToCountryModal = ({
  onClose,
  onConfirmation,
  shipToCountry,
  enforceAction,
  options,
  ...props
}: UpdateShipToCountryModalProps) => {
  const [selectedCountry, setSelectedCountry] = useState('');

  const closeModal = () => {
    setSelectedCountry('');
    onClose();
  };

  const dismissActions = enforceAction ? {} : { onDismiss: closeModal, onCancel: closeModal };

  return (
    <Modal
      keepMounted={false}
      title="Update Ship To Country"
      //@ts-expect-error Design-System Modal component only accepts strings as content
      content={
        <ModalContent
          options={options}
          country={selectedCountry || shipToCountry}
          onCountryChange={(newCountry: string) => setSelectedCountry(newCountry)}
        />
      }
      confirmButtonText="UPDATE"
      onConfirmation={() => {
        onConfirmation(selectedCountry || shipToCountry);
        closeModal();
      }}
      {...dismissActions}
      {...props}
    />
  );
};

export { UpdateShipToCountryModal };
