import { useEffect, useState } from 'react';
import { ButtonRounded, Flex, Modal, Notification } from '@Calix-Commerce/design-system';
import { DetailsRow, Title, ReOrderButton } from './styledComponents';
import { Container } from '@mui/system';
import {
  QuoteName,
  QuoteDescription,
  OrderDetails,
  RemoveItemModal,
  UnavailableItemsModal,
  UnsavedChangesModal,
  LockedQuoteModal,
  PriceUpdatedModal,
  ReorderModal,
  QuoteExpiredModal,
  ShipToCountryDisplay,
} from 'components';
import { QuoteDetailTable } from 'pages/QuoteDetails/subcomponents';
import { Tags } from 'pages/QuoteDetails/subcomponents/Tags';
import { CloneSvg, ExportSvg, ArchiveSvg } from '@Calix-Commerce/design-system/assets';
import AddGroupSVG from 'assets/add-group.svg';
import {
  TotalPrice,
  ButtonWrapper,
  IconActionWrapper,
  CheckoutButton,
  NotificationWrapper,
} from 'pages/QuoteDetails/subcomponents/styledComponents';
import { useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  authenticationState,
  currentQuoteValidationState,
  loadingState,
  loggedInAccountState,
  notificationState,
  selectedAccountState,
} from 'state/atoms';
import { storageCurrentQuoteSelector } from 'state/selectors';
import {
  CurrentQuoteState,
  CurrentQuoteValidationState,
  LineItem,
  QuoteGroupDetail,
  QuoteGroup as QuoteGroupNameAndId,
} from 'types';
import { getInvalidQtyItems, getQuoteGroupMapping } from 'utils/helpers/quote';
import {
  useAddressController,
  useAppNavigation,
  useFormatPrice,
  useQuoteActionController,
  useQuoteController,
} from 'utils/hooks';
import { formatDate } from 'utils/helpers/date';
import { AddGroupPopup } from 'pages/QuoteDetails/subcomponents/AddGroupPopup';
import { RemoveGroupModal } from 'pages/QuoteDetails/subcomponents/RemoveGroupModal';
import { useLazyQuery } from '@apollo/client';
import { SEARCH_PARTS_BY_TERM } from 'connectors/queries/partSearch';
import { PartSearchResponse } from 'types/state/Part';
import { defaultLineItem } from 'state/defaults';
import { InvalidQtyModal } from 'components/modals/InvalidQtyModal';
import { UpdateShipToCountryModal } from 'components/modals/UpdateShipToCountryModal';
import { withQuoteLoad } from 'hocs';
import { FloatingCard } from 'pages/Quotes/subcomponents/ResultsTable/FloatingCard';
import { getErrorMessage } from './errorMessages';
import { ErrorMessage } from 'types/Error';
import { VAR } from 'connectors/userTypes';
import { FileType } from 'types/FileType';

const QuoteDetailsPage = () => {
  const { transactionId } = useParams();
  const { accountId, currencyCode: selectedAccountCurrency } = useRecoilValue(selectedAccountState);
  const { currencyCode: loggedInAccountCurrency } = useRecoilValue(loggedInAccountState);
  const { userType, ecomCheckout } = useRecoilValue(authenticationState);
  const {
    loadQuote,
    alterQuote,
    createQuote,
    cloneQuote,
    alterArchivingStatus,
    refreshQuote,
    exportQuote,
    startCheckout,
  } = useQuoteActionController();
  const [currentQuote, setCurrentQuote] = useRecoilState(storageCurrentQuoteSelector);
  const validationState = useRecoilValue(currentQuoteValidationState);
  const setNotification = useSetRecoilState(notificationState);
  const setIsGlobalLoading = useSetRecoilState(loadingState);
  const formatPrice = useFormatPrice();
  const {
    getQuoteTotalWithExtendedWarrantyPriceLabel,
    getQuoteTotalWithoutExtendedWarrantyPriceLabel,
    removeGroup,
    updateQuoteItems,
    cloneQuoteGroup,
    reorderGroups,
    updateGroupName,
    updateQuoteDescription,
    updateQuoteName,
    addQuoteTag,
    addGroup,
    addItem,
    removeTag,
    removeItems,
    updateSelectedShipToCountry,
    selectedShipToCountry,
  } = useQuoteController();
  const {
    redirectToQuoteList,
    redirectToQuoteDetails,
    redirectToQuoteDetailsForNew,
    redirectToPartSearch,
    redirectToPartSearchForNew,
  } = useAppNavigation();
  const { favoriteShipToCountries } = useAddressController();
  const quoteGroups = getQuoteGroupMapping(currentQuote, formatPrice);
  const priceDisplay = currentQuote.isOrdered
    ? getQuoteTotalWithExtendedWarrantyPriceLabel()
    : getQuoteTotalWithoutExtendedWarrantyPriceLabel();
  const oneTimeDiscountPrice = currentQuote.calculatedOneTimeDiscount
    ? formatPrice(currentQuote.calculatedOneTimeDiscount)
    : null;
  const isNewQuote = Boolean(currentQuote.transactionId) === false;
  const isLoadingTheSameQuote = currentQuote.transactionId === transactionId;

  //modals
  const [showAddGroupPopup, setShowAddGroupPopup] = useState(false);
  const [showInvalidQtyModal, setShowInvalidQtyModal] = useState(false);
  const [cloneQuoteModal, setCloneQuoteModal] = useState(false);
  const [archiveQuoteModal, setArchiveQuoteModal] = useState(false);
  const [showExportModal, setExportModal] = useState(false);
  const [unsavedModal, setUnsavedModal] = useState(false);
  const [showUnavailableItemsModal, setShowUnavailableItemsModal] = useState(false);
  const [checkoutAfterInvalidQty, setCheckoutAfterInvalidQty] = useState(false);
  const [selectedShipToCountryModal, setSelectedShipToCountryModal] = useState(false);

  const [showReorderModal, setShowReorderModal] = useState(false);
  const [reorderQuoteData, setReorderQuoteData] = useState({
    newQuoteName: null,
    newQuoteDescription: null,
  } as { newQuoteName: string | null; newQuoteDescription: string | null });
  const [quoteInReorder, setQuoteInReorder] = useState<string | null>(null);

  const [removeItemModal, setRemoveItemModal] = useState<{
    removedItem: LineItem | null;
    show: boolean;
  }>({ removedItem: null, show: false });
  const [removeGroupModal, setRemoveGroupModal] = useState<{
    removedGroupId: number | null;
    show: boolean;
  }>({ removedGroupId: null, show: false });

  const [partSearchResults, setPartSearchResults] = useState(() =>
    quoteGroups.map(({ id }) => {
      return { id, results: [] };
    })
  );

  const isWarningApplicable = !validationState.isOrdered && isLoadingTheSameQuote;

  const showOneTimeDiscountDisabledActionWarning =
    validationState.hasOneTimeDiscounts && isWarningApplicable;

  const showMismatchCurrencyDisabledActionWarning =
    validationState.hasCurrencyMismatch && isWarningApplicable;

  const [searchForPart] = useLazyQuery(SEARCH_PARTS_BY_TERM, {
    fetchPolicy: 'no-cache',
  });
  const [searchForPartToAdd] = useLazyQuery(SEARCH_PARTS_BY_TERM, {
    fetchPolicy: 'no-cache',
  });

  const readonly = !validationState.isWritable;

  useEffect(() => {
    const hasExistingUnsavedQuoteData = validationState.isModified;

    if (transactionId && hasExistingUnsavedQuoteData && !isLoadingTheSameQuote && !quoteInReorder) {
      setUnsavedModal(true);
    }

    if (
      validationState.hasUnavailableItems &&
      validationState.hasWritePermission &&
      !currentQuote.isOrdered &&
      isLoadingTheSameQuote
    ) {
      setShowUnavailableItemsModal(true);
    }
  }, [
    accountId,
    transactionId,
    validationState,
    isLoadingTheSameQuote,
    currentQuote.isOrdered,
    quoteInReorder,
  ]);

  const displayNotification = (
    notificationText: string,
    type: 'success' | 'error',
    timeout?: number
  ) =>
    setNotification({
      text: notificationText,
      type,
      timeout: timeout || null,
      show: true,
    });

  const handleReorderAlter = (quote: CurrentQuoteState) => {
    const newCurrentState = {
      ...quote,
      modified: true,
    };
    setCurrentQuote(newCurrentState);

    if (validationState.hasInvalidItemQuantity) {
      setShowInvalidQtyModal(true);
      setCheckoutAfterInvalidQty(true);
      setIsGlobalLoading(false);
      return;
    }

    alterQuote({ quoteData: newCurrentState, validationData: validationState })
      .then((alteredCurrentQuote) => {
        startCheckout({ quoteData: alteredCurrentQuote, validationData: validationState });
      })
      .catch(() => {
        displayNotification(
          'Something went wrong during the final part of reorder. Please try again. If this issue persists, please contact your customer operations representative.',
          'error'
        );
      })
      .finally(() => {
        setIsGlobalLoading(false);
        setReorderQuoteData({ newQuoteName: null, newQuoteDescription: null });
      });
  };

  const addPart = (partNumber: string, groupId: number, groupName: string) => {
    setIsGlobalLoading(true);

    searchForPartToAdd({
      variables: {
        input: {
          requestedAccountId: accountId,
          term: '',
          currencyCode: currentQuote.currencyCode,
          partNumbers: [partNumber],
          shippingCountry: selectedShipToCountry,
        },
      },
      onCompleted: (data) => {
        const part: PartSearchResponse = data.searchPartsByTerm.at(0);

        if (!part) {
          setIsGlobalLoading(false);
          return;
        }

        const hasWarrantyEligibleItems = part.bom.some(
          ({ productDetails }) => productDetails.extendedWarrantyEligible
        );

        addItem({
          ...defaultLineItem,
          ...part,
          quantity: 1,
          groupNumber: groupId,
          groupName,
          packageBom: part.bom.map((bom) => {
            return {
              partNumber: bom.partNumber,
              quantity: bom.quantity,
              productDetails: {
                description: bom.productDetails.description,
                extendedWarrantyEligible: bom.productDetails.extendedWarrantyEligible,
              },
            };
          }),
          totalPrice: part.sellPrice,
          hasWarrantyEligibleItems,
        });
        setIsGlobalLoading(false);
      },
    });
  };

  const searchPartsByTerm = (term: string, groupId: number) => {
    if (term.length < 3) return;

    searchForPart({
      variables: {
        input: {
          requestedAccountId: accountId,
          term,
          currencyCode: currentQuote.currencyCode,
          limit: 5,
          shippingCountry: selectedShipToCountry,
        },
      },
      onCompleted: (data) => {
        const groupExist = partSearchResults.find(({ id }) => id === groupId);

        if (groupExist) {
          setPartSearchResults((prev) =>
            prev.map((groupResults) => {
              return {
                ...groupResults,
                results:
                  groupResults.id === groupId ? data?.searchPartsByTerm : groupResults.results,
              };
            })
          );
        } else {
          setPartSearchResults((prev) => {
            return [...prev, { id: groupId, results: data.searchPartsBytTerm }];
          });
        }
      },
    });
  };

  const onSaveUnsavedChanges = () => {
    setUnsavedModal(false);
    setIsGlobalLoading(true);

    if (!isNewQuote) {
      alterQuote({ quoteData: currentQuote, validationData: validationState })
        .then(() => {
          displayNotification('Saved with success.', 'success', 5000);
        })
        .then(() => {
          loadQuote({ transactionId, overwriteLocalQuote: true }).then(() =>
            setIsGlobalLoading(false)
          );
        })
        .catch((error) => {
          displayNotification(getErrorMessage(error), 'error');
        });
    } else {
      createQuote({
        quoteData: { ...currentQuote, accountId },
        validationData: validationState,
        redirectToNewQuote: false,
      })
        .then(() => {
          displayNotification('Saved with success.', 'success', 5000);
        })
        .then(() => {
          loadQuote({ transactionId, overwriteLocalQuote: true }).then(() =>
            setIsGlobalLoading(false)
          );
        })
        .catch((error) => {
          displayNotification(getErrorMessage(error), 'error');
        });
    }
  };

  const saveQuote = ({
    quoteData,
    validationData,
  }: {
    quoteData: CurrentQuoteState;
    validationData: CurrentQuoteValidationState;
  }) => {
    if (validationData.hasInvalidItemQuantity) {
      setShowInvalidQtyModal(true);
      return;
    }

    setIsGlobalLoading(true);

    if (!isNewQuote) {
      alterQuote({ quoteData, validationData })
        .then((response) => {
          setCurrentQuote(response);
          displayNotification('Saved with success.', 'success', 5000);
        })
        .catch((error) => {
          displayNotification(getErrorMessage(error), 'error');
        })
        .finally(() => {
          setIsGlobalLoading(false);
        });
    } else {
      createQuote({ quoteData, validationData })
        .then(() => {
          displayNotification('Saved with success.', 'success', 5000);
        })
        .catch((error) => {
          displayNotification(getErrorMessage(error), 'error');
        })
        .finally(() => {
          setIsGlobalLoading(false);
        });
    }
  };

  const getCloneErrors = () => {
    if (validationState.hasCurrencyMismatch) {
      const accountCurrency = userType === VAR ? loggedInAccountCurrency : selectedAccountCurrency;
      return `Quote cannot be cloned because the quote currency(${currentQuote.currencyCode}) and account currency(${accountCurrency}) are different.`;
    }

    if (validationState.hasOneTimeDiscounts) {
      return 'Quotes containing One Time Discount cannot be cloned.';
    }
  };

  const onCloneQuote = () => {
    setCloneQuoteModal(false);

    const cloningError = getCloneErrors();

    if (cloningError) {
      displayNotification(cloningError, 'error');
      return;
    }

    setIsGlobalLoading(true);

    cloneQuote({ quoteData: currentQuote, validationData: validationState })
      .then((response) => {
        redirectToQuoteDetails(response.transactionId);
      })
      .catch((error) => {
        displayNotification(error.message, 'error');
      })
      .finally(() => setIsGlobalLoading(false));
  };

  const onArchiveQuote = () => {
    if (isNewQuote) {
      return;
    }

    setIsGlobalLoading(true);

    const { transactionId, archived } = currentQuote;
    const isArchiving = !archived;

    alterArchivingStatus({ transactionId, archived: isArchiving })
      .then((response) => {
        setCurrentQuote(response);
        setArchiveQuoteModal(false);
        displayNotification(
          `Quote ${isArchiving ? 'archived' : 'unarchived'} with success`,
          'success',
          5000
        );

        if (isArchiving) {
          redirectToQuoteList();
        }
      })
      .catch((error) => {
        displayNotification(error.message, 'error');
      })
      .finally(() => setIsGlobalLoading(false));
  };

  const onCheckout = ({
    quoteData,
    validationData,
  }: {
    quoteData: CurrentQuoteState;
    validationData: CurrentQuoteValidationState;
  }) => {
    if (quoteData.isOrdered) {
      displayNotification(
        'This order has already been submitted. You can clone this quote if you would like to make an identical or similar order.',
        'error'
      );
    }

    setIsGlobalLoading(true);
    startCheckout({ quoteData, validationData })
      .catch((error) => {
        displayNotification(getErrorMessage(error), 'error');

        if (error.message === ErrorMessage.HAS_INVALID_ITEM_QUANTITY) {
          setShowInvalidQtyModal(true);
          setCheckoutAfterInvalidQty(true);
        }
      })
      .finally(() => setIsGlobalLoading(false));
  };

  const refreshPrices = () => {
    setIsGlobalLoading(true);
    refreshQuote({ transactionId: currentQuote.transactionId })
      .catch(() => {
        displayNotification('Error refreshing the quote', 'error');
      })
      .finally(() => setIsGlobalLoading(false));
  };

  const reorderQuoteModal = () => {
    setReorderQuoteData({
      newQuoteName: currentQuote.quoteName,
      newQuoteDescription: currentQuote.quoteDescription,
    });
    setShowReorderModal(true);
  };

  const reorderQuote = ({ newQuoteName, newQuoteDescription }) => {
    const cloningError = getCloneErrors();

    if (cloningError) {
      displayNotification(cloningError, 'error');
      return;
    }

    setQuoteInReorder(currentQuote.transactionId);
    cloneQuote({ quoteData: currentQuote, validationData: validationState })
      .then((quote) =>
        handleReorderAlter({
          ...quote,
          quoteName: newQuoteName || currentQuote.quoteName,
          quoteDescription: newQuoteDescription,
        })
      )
      .catch(() => {
        setReorderQuoteData({ newQuoteName: null, newQuoteDescription: null });
        setQuoteInReorder(null);
        setShowReorderModal(false);
        displayNotification(
          'Something went wrong during the reorder. Please try again. If this issue persists, please contact your customer operations representative.',
          'error'
        );
      });
  };

  const cancelUnsavedChangesModal = () => {
    setUnsavedModal(false);
    if (currentQuote.transactionId) {
      redirectToQuoteDetails(currentQuote.transactionId);
    } else {
      redirectToQuoteDetailsForNew();
    }
  };

  const discardUnsavedChangesModal = () => {
    setIsGlobalLoading(true);
    loadQuote({ transactionId, overwriteLocalQuote: true }).then(() => {
      setIsGlobalLoading(false);
      setUnsavedModal(false);
    });
  };

  const confirmInvalidQtyModal = (updatedItems: LineItem[]) => {
    const quoteData = updateQuoteItems(
      currentQuote.items.map((item) => {
        const updatedItem = updatedItems.find(
          (updatedItem) => updatedItem.sequenceNumber === item.sequenceNumber
        );
        return updatedItem ? { ...item, quantity: updatedItem.quantity } : item;
      })
    );
    const validationData = { ...validationState, hasInvalidItemQuantity: false };

    setShowInvalidQtyModal(false);
    checkoutAfterInvalidQty
      ? onCheckout({ quoteData, validationData })
      : saveQuote({ quoteData, validationData });
    setCheckoutAfterInvalidQty(false);
  };

  const exportPDF = () => {
    setExportModal(false);
    setIsGlobalLoading(true);
    exportQuote({ transactionId, fileType: FileType.PDF })
      .then(() => setIsGlobalLoading(false))
      .catch(() => {
        displayNotification(
          'Unable to export quote. If this issue persists, please contact your customer operations representative.',
          'error'
        );
      })
      .finally(() => setIsGlobalLoading(false));
  };

  const exportXLS = () => {
    setExportModal(false);
    setIsGlobalLoading(true);
    exportQuote({ transactionId, fileType: FileType.XLS })
      .then(() => setIsGlobalLoading(false))
      .catch(() => {
        displayNotification(
          'Unable to export quote. If this issue persists, please contact your customer operations representative.',
          'error'
        );
      })
      .finally(() => setIsGlobalLoading(false));
  };

  const updateQuote = (newQuoteGroups: QuoteGroupDetail[]) => {
    const mappedQuoteItems: LineItem[] = newQuoteGroups.reduce((newItems: LineItem[], group) => {
      return [
        ...newItems,
        ...group.items.map((item) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { id, imgSource, ...updatedItem } = item;

          return updatedItem;
        }),
      ];
    }, []);

    updateQuoteItems(mappedQuoteItems);
  };

  const handleRemoveGroup = (removedGroup: QuoteGroupDetail) => {
    setRemoveGroupModal({
      removedGroupId: removedGroup.id,
      show: true,
    });
  };

  const handleCloneGroup = (clonedGroup: QuoteGroupDetail) => {
    cloneQuoteGroup({ groupName: clonedGroup.groupName, items: clonedGroup.items });
  };

  const handleRenameGroup = (quoteGroupId: number, newGroupName: string) => {
    updateGroupName(quoteGroupId, newGroupName);
  };

  const handleRemoveItem = (removedItem: LineItem) => {
    setRemoveItemModal({ removedItem: removedItem, show: true });
  };

  const handleRedirectToSearch = () => {
    if (isNewQuote) {
      redirectToPartSearchForNew();
    } else {
      redirectToPartSearch();
    }
  };

  const handleReorderGroups = (groupOrder: QuoteGroupNameAndId[]) => {
    reorderGroups(groupOrder);
  };

  const handleQuoteExpired = () => {
    redirectToQuoteList();
  };

  const checkoutOrReorderButton = currentQuote.isOrdered ? (
    <ReOrderButton
      disabled={!validationState.hasWritePermission || validationState.hasCurrencyMismatch}
      onClick={reorderQuoteModal}
    >
      REORDER
    </ReOrderButton>
  ) : (
    <CheckoutButton
      disabled={!validationState.isCheckoutAllowed}
      onClick={() => onCheckout({ quoteData: currentQuote, validationData: validationState })}
    >
      CHECKOUT
    </CheckoutButton>
  );

  const onUpdateShipToCountry = async (newSelectedCountry: string) => {
    const shipToCountryChanged = newSelectedCountry === selectedShipToCountry;
    updateSelectedShipToCountry(newSelectedCountry);

    if (!transactionId || shipToCountryChanged) {
      return;
    }
    setIsGlobalLoading(true);
    await refreshQuote({
      transactionId,
      newShipToCountry: newSelectedCountry,
      refreshProductData: true,
    });
    setIsGlobalLoading(false);
  };

  return (
    <Container>
      <UnsavedChangesModal
        isOpen={unsavedModal}
        openingQuoteTransactionId={transactionId as string}
        //redirect back to the unsaved quote
        onCancel={cancelUnsavedChangesModal}
        onConfirmation={onSaveUnsavedChanges}
        unsavedQuoteTransactionId={currentQuote.transactionId}
        onDiscard={discardUnsavedChangesModal}
      />
      <QuoteExpiredModal isOpen={validationState.isExpired} onConfirmation={handleQuoteExpired} />
      <LockedQuoteModal
        isOpen={
          validationState.isLockedByAnotherUser && isLoadingTheSameQuote && !currentQuote.isOrdered
        }
        onClose={redirectToQuoteList}
        onConfirmation={onCloneQuote}
      />
      <PriceUpdatedModal
        isOpen={
          validationState.hasOutdatedDiscounts && isLoadingTheSameQuote && !currentQuote.isOrdered
        }
        onConfirmation={refreshPrices}
      />
      <UnavailableItemsModal
        isOpen={showUnavailableItemsModal}
        items={currentQuote.items.filter(({ available }) => !available)}
        onClose={() => setShowUnavailableItemsModal(false)}
        onConfirmation={() => {
          removeItems(
            currentQuote.items
              .filter(({ available }) => !available)
              .map(({ sequenceNumber }) => sequenceNumber)
          );
          setShowUnavailableItemsModal(false);
        }}
      />
      <RemoveItemModal
        isOpen={removeItemModal.show}
        item={removeItemModal.removedItem}
        onClose={() => {
          setRemoveItemModal({ removedItem: null, show: false });
        }}
      />
      <RemoveGroupModal
        isOpen={removeGroupModal.show}
        onClose={() => {
          setRemoveGroupModal({ removedGroupId: null, show: false });
        }}
        onConfirmation={() => {
          if (removeGroupModal.removedGroupId) {
            removeGroup(removeGroupModal.removedGroupId);
          }
        }}
      />
      {showInvalidQtyModal ? (
        <InvalidQtyModal
          isOpen={true}
          onClose={() => setShowInvalidQtyModal(false)}
          onConfirmation={(updatedItems: LineItem[]) => {
            confirmInvalidQtyModal(updatedItems);
          }}
          items={getInvalidQtyItems(currentQuote.items)}
        />
      ) : null}
      <Modal
        isOpen={cloneQuoteModal}
        title="Clone Quote"
        content={`${validationState.isModified ? 'Any unsaved data in the current quote will be lost.<br></br>' : ''}Are you sure you want to clone this quote?`}
        flag={validationState.isModified ? 'Warning' : ''}
        onCancel={() => setCloneQuoteModal(false)}
        onDismiss={() => setCloneQuoteModal(false)}
        onConfirmation={onCloneQuote}
        confirmButtonText="CONFIRM"
      />
      <Modal
        isOpen={archiveQuoteModal}
        title={`${currentQuote.archived ? 'Unarchive' : 'Archive'} Quote`}
        content={`Any unsaved data in the current quote will be lost.<br></br>Are you sure you want to ${currentQuote.archived ? 'Unarchive' : 'Archive'} this quote?`}
        onCancel={() => setArchiveQuoteModal(false)}
        onDismiss={() => setArchiveQuoteModal(false)}
        onConfirmation={onArchiveQuote}
        confirmButtonText="CONFIRM"
        flag="Warning"
      />
      <ReorderModal
        isOpen={showReorderModal}
        onClose={() => setShowReorderModal(false)}
        onConfirmation={({ newQuoteName, newQuoteDescription }) => {
          setShowReorderModal(false);
          setIsGlobalLoading(true);
          reorderQuote({ newQuoteName, newQuoteDescription });
        }}
        initialQuoteName={reorderQuoteData.newQuoteName}
        initialQuoteDescription={reorderQuoteData.newQuoteDescription}
      ></ReorderModal>
      <UpdateShipToCountryModal
        isOpen={selectedShipToCountryModal || !currentQuote.shippingAddress.country}
        shipToCountry={currentQuote.shippingAddress.country || ''}
        onConfirmation={onUpdateShipToCountry}
        options={favoriteShipToCountries}
        onClose={() => setSelectedShipToCountryModal(false)}
        enforceAction={!currentQuote.shippingAddress.country}
      />
      <div>
        <Title>Quote Detail</Title>
        <Flex spacing="space-between">
          <Flex direction="column">
            <QuoteName
              readonly={readonly || currentQuote.isOrdered}
              placeholder={'Name'}
              quoteName={currentQuote.quoteName}
              onEdit={(newQuoteName) => {
                updateQuoteName(newQuoteName);
              }}
            />
            <QuoteDescription
              readonly={readonly || currentQuote.isOrdered}
              quoteDescription={currentQuote.quoteDescription}
              onEdit={(newQuoteDescription) => {
                updateQuoteDescription(newQuoteDescription);
              }}
              placeholder={'Description'}
            ></QuoteDescription>
            <ShipToCountryDisplay
              readonly={readonly || currentQuote.isOrdered}
              shipToCountry={currentQuote.shippingAddress.country || 'None Selected'}
              onClick={() => setSelectedShipToCountryModal(true)}
            />
          </Flex>
          {currentQuote.isOrdered ? (
            <Flex direction="column" style={{ width: '33.3%' }}>
              <DetailsRow>
                <span>Order Submitted:</span>
                <span>
                  {currentQuote.orderSubmittedDate
                    ? formatDate(currentQuote.orderSubmittedDate)
                    : ''}
                </span>
              </DetailsRow>
              <DetailsRow>
                <span>PO:</span>
                <span>{currentQuote.poNumber}</span>
              </DetailsRow>
              <DetailsRow>
                <span>Total:</span>
                <span>{priceDisplay}</span>
              </DetailsRow>
            </Flex>
          ) : (
            <TotalPrice>Total: {priceDisplay}</TotalPrice>
          )}
        </Flex>
        <Tags
          tags={currentQuote.tags}
          removeTag={(tagToRemove) => removeTag(tagToRemove)}
          addTag={(newTag) => {
            addQuoteTag(newTag);
          }}
          readonly={readonly || currentQuote.isOrdered}
        ></Tags>
        <ButtonWrapper>
          {!currentQuote.isOrdered ? (
            <>
              <ButtonRounded disabled={readonly} onClick={handleRedirectToSearch}>
                SEARCH FOR NEW ITEM
              </ButtonRounded>
              <ButtonRounded
                disabled={readonly}
                onClick={() =>
                  saveQuote({ quoteData: currentQuote, validationData: validationState })
                }
              >
                SAVE QUOTE
              </ButtonRounded>
              {!readonly ? (
                <div style={{ position: 'relative', margin: '0' }}>
                  <IconActionWrapper disabled={readonly} onClick={() => setShowAddGroupPopup(true)}>
                    {/*@ts-expect-error svg configuration error*/}
                    <AddGroupSVG className="add-new-group"></AddGroupSVG>
                    <p>Add New Group</p>
                  </IconActionWrapper>
                  <AddGroupPopup
                    show={showAddGroupPopup}
                    onConfirm={(newGroupName) => {
                      addGroup(newGroupName);
                      setShowAddGroupPopup(false);
                    }}
                    onClose={() => setShowAddGroupPopup(false)}
                    expandDuration={0.3}
                  />
                </div>
              ) : null}
            </>
          ) : null}
          <IconActionWrapper
            disabled={readonly}
            onClick={() => {
              if (!transactionId) {
                displayNotification('Please save the quote before cloning', 'error');
                return;
              }
              setCloneQuoteModal(true);
            }}
          >
            <CloneSvg></CloneSvg>
            <p>Clone Quote</p>
          </IconActionWrapper>
          {!isNewQuote ? (
            <IconActionWrapper
              onClick={() => {
                setArchiveQuoteModal(true);
              }}
            >
              <ArchiveSvg></ArchiveSvg>
              <p>{currentQuote.archived ? 'Unarchive' : 'Archive'}</p>
            </IconActionWrapper>
          ) : null}
          <div style={{ position: 'relative' }}>
            <IconActionWrapper disabled={readonly} onClick={() => setExportModal(true)}>
              <ExportSvg></ExportSvg>
              <p>Save To File</p>
            </IconActionWrapper>
            {showExportModal ? (
              <FloatingCard
                isModified={validationState.isModified}
                onClickOutside={() => setExportModal(false)}
                onExportPDF={exportPDF}
                onExportXLS={exportXLS}
              />
            ) : null}
          </div>
          {ecomCheckout && checkoutOrReorderButton}
        </ButtonWrapper>
        <NotificationWrapper>
          <Notification
            show={showOneTimeDiscountDisabledActionWarning}
            type="warning"
            fixed={false}
            zIndex={10}
          >
            Clone and Save To File are disabled when One Time Discounts are present.
          </Notification>
          <Notification
            show={showMismatchCurrencyDisabledActionWarning}
            type="warning"
            fixed={false}
            zIndex={10}
          >
            Clone and Save To File are disabled when there is a mismatch between quote and account
            currencies.
          </Notification>
        </NotificationWrapper>
        <QuoteDetailTable
          quoteGroups={quoteGroups}
          handleAddPart={(partNumber, groupId, groupName) =>
            addPart(partNumber, groupId, groupName)
          }
          handleQuoteUpdate={(newQuoteGroups) => updateQuote(newQuoteGroups)}
          handleRenameGroup={(quoteGroupId, newName) => handleRenameGroup(quoteGroupId, newName)}
          handleRemoveItem={(removedItem) => handleRemoveItem(removedItem)}
          handleReorderGroups={(groupOrder) => handleReorderGroups(groupOrder)}
          onRemoveGroup={(removedGroup) => handleRemoveGroup(removedGroup)}
          onCloneGroup={(clonedGroup) => handleCloneGroup(clonedGroup)}
          searchResults={partSearchResults}
          searchPartNumbers={(searchTerm, groupId) => searchPartsByTerm(searchTerm, groupId)}
          readonly={readonly || currentQuote.isOrdered}
          allowRemovalOnReadOnly={
            validationState.hasUnavailableItems &&
            validationState.hasWritePermission &&
            !currentQuote.isOrdered
          }
          quoteTotalPrice={priceDisplay}
          oneTimeDiscountPrice={oneTimeDiscountPrice}
          isQuoteOrdered={currentQuote.isOrdered}
        ></QuoteDetailTable>
        {currentQuote.isOrdered ? <OrderDetails quote={currentQuote}></OrderDetails> : null}
      </div>
    </Container>
  );
};

const QuoteDetails = withQuoteLoad(QuoteDetailsPage, false);

export { QuoteDetails };
