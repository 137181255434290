import {
  AuthenticationState,
  InfoModalState,
  NotificationState,
  Account,
  CurrentQuoteState,
  CurrentQuoteValidationState,
  FeedbackCollectedState,
  QuoteGroup,
  LineItem,
  CheckoutAddress,
} from 'types';
import { UNASSIGNED_GROUP_ID, UNASSIGNED_GROUP_LABEL } from 'utils/constants';

export const defaultAuthenticationState: AuthenticationState = {
  isAuthenticated: false,
  account: '',
  accountId: '',
  endCustomerAccountId: '',
  varAccountId: '',
  ecomAccess: false,
  ecomCheckout: false,
  eCommerceAccess: [],
  email: '',
  userSFid: '',
  userType: '',
  userName: '',
};

export const defaultLoadingState: boolean = true;
export const defaultIsLockingQuoteState: boolean = false;

export const defaultInfoModalState: InfoModalState = {
  open: false,
  config: { title: '', content: '' },
};

export const defaultNotificationState: NotificationState = {
  text: '',
  show: false,
  type: 'success',
  timeout: null,
};

export const defaultAccountState: Account = {
  accountId: '',
  accountName: '',
  accountLead: {},
  currencyCode: 'USD',
  businessCountry: '',
  accountManagerId: '',
  commerceComplete: false,
  priceType: '',
};

export const defaultLineItem: LineItem = {
  documentNumber: undefined,
  parentDocumentNumber: null,
  modelName: null,
  lineItemId: null,
  images: [],
  quantity: 0,
  listPrice: 0,
  salePrice: '',
  sellPrice: 0,
  priceLine: '',
  totalPrice: 0,
  partId: '',
  groupNumber: 0,
  groupName: '',
  sequenceNumber: 0,
  partNumber: '',
  description: '',
  partName: '',
  lastModifiedDate: '',
  discountBy: '',
  ongoingLine: null,
  oneTimeDiscount: null,
  optional: null,
  lineItemDiscount: 0,
  status: null,
  available: true,
  packageBom: [],
  warrantyForPartNumber: null,
  discountOutdated: false,
  extendedWarrantyEligible: false,
  extendedWarrantyQuantity: 0,
  hasWarrantyEligibleItems: false,
  customSku: false,
  discountType: '',
  maximumOrderQuantity: null,
  minimumOrderQuantity: null,
  subscriptionDuration: 0,
};

export const defaultCurrentQuoteState: CurrentQuoteState = {
  transactionId: '',
  lockedBy: '',
  lockExpiry: '',
  eCommercePermissions: [],
  lastModifiedDate: '',
  modified: false,
  isOrdered: false,
  accountId: '',
  projectId: '',
  quoteNumber: '',
  option: '',
  revisionNumber: 0,
  quoteName: '',
  quoteStatus: '',
  quoteDescription: '',
  preparedByName: '',
  currencyCode: 'USD',
  total: 0,
  archived: false,
  containsNonDiscoutedDiscountableItems: false,
  calculatedOneTimeDiscount: 0,
  tags: [],
  createdDate: '',
  opportunityId: '',
  poNumber: '',
  salesOrderNumber: 0,
  accountName: '',
  commerceComplete: false,
  preparedByEmail: '',
  expirationDate: '',
  totalLineItemDiscounts: 0,
  totalNetPrice: 0,
  totalDiscount: '',
  totalDiscountPercent: 0,
  warrantyTotal: 0,
  creator: '',
  opportunityName: '',
  requiresWarrantyConfiguration: false,
  quoteGroups: [{ name: UNASSIGNED_GROUP_LABEL, id: UNASSIGNED_GROUP_ID } as QuoteGroup],
  items: [],
  orderSubmittedDate: null,
  packingInstructions: null,
  shippingInstructions: null,
  multipleShipments: null,
  shipmentSchedule: [],
  requestedShippingType: '',
  requestedShippingDate: '',
  shippingContactLastName: null,
  shippingContactFirstName: null,
  confirmationRecipients: [],
  shippingContactPhone: {
    countryCode: null,
    areaCode: null,
    number: null,
    extension: null,
  },
  shippingContactEmail: null,
  shippingAddress: {
    name: '',
    address1: '',
    address2: null,
    city: '',
    state: '',
    zipcode: '',
    country: '',
  },
  poAttachment: null,
  lesingAgent: false,
  taxExempt: '',
  billingAddress: {
    name: '',
    address1: '',
    address2: null,
    city: '',
    state: '',
    zipcode: '',
    country: '',
  },
  warrantyInput: [],
};

export const defaultCurrentQuoteValidationState: CurrentQuoteValidationState = {
  isOrdered: false,
  isApproved: false,
  isWritable: true,
  isCheckoutAllowed: false,
  isExpired: false,
  hasWritePermission: true,
  isModified: true,
  isEmpty: true,
  hasOneTimeDiscounts: false,
  allItemsQuotable: true,
  allItemsDiscounted: true,
  hasCurrencyMismatch: false,
  hasOutdatedDiscounts: false,
  hasInvalidItemQuantity: false,
  hasWarrantyEligibleItems: false,
  hasItemsWithQuantityZero: false,
  hasUnavailableItems: false,
  hasShipToCountry: false,
  isLockedByCurrentUser: false,
  isLockedByAnotherUser: false,
};

export const defaultFeedbackCollectedState: FeedbackCollectedState = {
  isFeedbackCollected: null,
};

export const defaultAddressState: CheckoutAddress[] = [];
