import maxBy from 'lodash/maxBy';
import { useRecoilState, useRecoilValue } from 'recoil';
import { storageCurrentQuoteSelector, storageSelectedAccountSelector } from 'state/selectors';
import { ItemStatus, LineItem, QuoteGroup } from 'types';
import {
  CONTACT_COPS_LABEL,
  NOT_AVAILABLE_LABEL,
  NULL_GROUP_ID,
  UNASSIGNED_GROUP_ID,
  UNASSIGNED_GROUP_LABEL,
} from 'utils/constants';
import { useAppNavigation, useFormatPrice, useAddressController } from '..';
import { defaultCurrentQuoteState } from 'state/defaults';
import { getQuoteName } from 'utils/helpers/quote';
import { useCallback, useMemo } from 'react';
import {
  authenticationState,
  currentQuoteValidationState,
  loggedInAccountState,
} from 'state/atoms';
import { CALIX_EMPLOYEE } from 'connectors/userTypes';
import { useLocation } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';
import { resetSearchData } from 'utils/helpers/cache';
import { getSavedCheckoutData, saveCheckoutDataInLocalStorage } from 'utils/helpers/checkout';

export function useQuoteController() {
  const apolloClient = useApolloClient();
  const { userType } = useRecoilValue(authenticationState);
  const [currentQuoteOriginal, setCurrentQuoteState] = useRecoilState(storageCurrentQuoteSelector);
  const currentQuote = useMemo(
    () => ({ ...currentQuoteOriginal, modified: true }),
    [currentQuoteOriginal]
  );
  const validationState = useRecoilValue(currentQuoteValidationState);
  const loggedInAccount = useRecoilValue(loggedInAccountState);
  const selectedAccount = useRecoilValue(storageSelectedAccountSelector);
  const { defaultShipToCountry } = useAddressController();
  const formatPrice = useFormatPrice();
  const { redirectToPartSearchForNew } = useAppNavigation();
  const location = useLocation();

  const defaultCurrencyCode = useMemo(() => {
    return userType === CALIX_EMPLOYEE
      ? selectedAccount.currencyCode
      : loggedInAccount.currencyCode;
  }, [userType, loggedInAccount, selectedAccount]);

  const createNewQuote = () => {
    if (currentQuoteOriginal.currencyCode !== defaultCurrencyCode) {
      resetSearchData(apolloClient.cache);
    }

    setCurrentQuoteState({
      ...defaultCurrentQuoteState,
      accountId: selectedAccount.accountId,
      quoteName: getQuoteName(),
      currencyCode: defaultCurrencyCode,
      shippingAddress: {
        ...defaultCurrentQuoteState.shippingAddress,
        country: defaultShipToCountry,
      },
    });
  };

  const createFirstQuote = () => {
    if (currentQuoteOriginal.currencyCode !== defaultCurrencyCode) {
      resetSearchData(apolloClient.cache);
    }

    setCurrentQuoteState({
      ...defaultCurrentQuoteState,
      accountId: selectedAccount.accountId,
      quoteName: 'My First Quote',
      currencyCode: defaultCurrencyCode,
      shippingAddress: {
        ...defaultCurrentQuoteState.shippingAddress,
        country: defaultShipToCountry,
      },
    });

    if (!location.state?.preventRedirect) {
      redirectToPartSearchForNew();
    }
  };

  const updateQuoteName = useCallback(
    (newQuoteName: string) => {
      setCurrentQuoteState({
        ...currentQuote,
        quoteName: newQuoteName,
      });
    },
    [setCurrentQuoteState, currentQuote]
  );

  const updateQuoteDescription = useCallback(
    (newQuoteDescription: string) => {
      setCurrentQuoteState({
        ...currentQuote,
        quoteDescription: newQuoteDescription,
      });
    },
    [setCurrentQuoteState, currentQuote]
  );

  const getTotalPrice = useCallback(
    (items: LineItem[]) => {
      return (
        items.reduce(
          (total, item) =>
            total + (item.status !== ItemStatus.REMOVED && item.totalPrice ? item.totalPrice : 0),
          0
        ) - currentQuote.calculatedOneTimeDiscount
      );
    },
    [currentQuote.calculatedOneTimeDiscount]
  );

  const getQuoteTotalWithExtendedWarrantyPriceLabel = () => {
    if (validationState.hasUnavailableItems && !currentQuote.isOrdered) {
      return NOT_AVAILABLE_LABEL;
    }

    const contactCOps = currentQuote.items.some(({ discountType, status }) => {
      return discountType === null && ItemStatus.REMOVED !== status;
    });

    if (contactCOps) {
      return CONTACT_COPS_LABEL;
    }

    return formatPrice(getTotalPrice(currentQuote.items));
  };

  const getQuoteTotalWithoutExtendedWarrantyPriceLabel = () => {
    if (validationState.hasUnavailableItems && !currentQuote.isOrdered) {
      return NOT_AVAILABLE_LABEL;
    }

    const contactCOps = currentQuote.items.some(({ discountType, status }) => {
      return discountType === null && ItemStatus.REMOVED !== status;
    });

    if (contactCOps) {
      return CONTACT_COPS_LABEL;
    }

    return formatPrice(
      getTotalPrice(currentQuote.items.filter(({ priceLine }) => priceLine !== 'Extended Warranty'))
    );
  };

  const addGroup = (newGroupName: string) => {
    const newQuoteGroups = [
      { name: newGroupName, id: currentQuote.quoteGroups.length + 1 },
      ...currentQuote.quoteGroups,
    ];

    setCurrentQuoteState({
      ...currentQuote,
      quoteGroups: newQuoteGroups,
    });
  };

  const addItem = useCallback(
    (item: LineItem) => {
      let newQuoteGroups: QuoteGroup[] = currentQuote.quoteGroups;
      let newLines: LineItem[] = [];
      const newItem: LineItem = {
        ...item,
        sequenceNumber: currentQuote.items.length + 1,
        status: ItemStatus.CREATE,
      };

      const groupExist = item.groupNumber !== NULL_GROUP_ID;

      if (!groupExist) {
        const newGroupId = currentQuote.quoteGroups.length + 1;
        newQuoteGroups = [
          { name: item.groupName as string, id: newGroupId },
          ...currentQuote.quoteGroups,
        ];
        newItem.groupNumber = newGroupId;
      }

      newLines = [...currentQuote.items, newItem];

      setCurrentQuoteState({
        ...currentQuote,
        quoteGroups: newQuoteGroups,
        items: newLines,
        total: getTotalPrice(newLines),
      });
    },
    [setCurrentQuoteState, getTotalPrice, currentQuote]
  );

  const updateQuoteItems = (newGroupItems: LineItem[]) => {
    const newLineItems = currentQuote.items.map((item) => {
      const updatedItem = newGroupItems.find(
        ({ sequenceNumber }) => item.sequenceNumber === sequenceNumber
      );

      const itemQuantityChanged = updatedItem?.quantity !== item.quantity;
      const itemGroupChanged = updatedItem?.groupNumber !== item.groupNumber;

      const itemModified = itemQuantityChanged || itemGroupChanged;

      return !itemModified || !updatedItem
        ? item
        : {
            ...updatedItem,
            status:
              updatedItem.documentNumber && itemModified ? ItemStatus.MODIFIED : updatedItem.status,
            totalPrice: updatedItem.sellPrice * updatedItem.quantity,
          };
    });

    const newCurrentQuote = {
      ...currentQuote,
      items: newLineItems,
      total: getTotalPrice(newLineItems),
    };

    setCurrentQuoteState(newCurrentQuote);

    return newCurrentQuote;
  };

  const removeItem = (sequenceNumber: number) => {
    const newLineItems = currentQuote.items
      .filter((item) => (item.documentNumber ? true : item.sequenceNumber !== sequenceNumber))
      .map((item) => {
        if (item.sequenceNumber === sequenceNumber) {
          return {
            ...item,
            status: ItemStatus.REMOVED,
          };
        } else {
          return item;
        }
      });

    setCurrentQuoteState({
      ...currentQuote,
      items: newLineItems,
      total: getTotalPrice(newLineItems),
    });
  };

  const removeItems = (sequenceNumbers: number[]) => {
    const newLineItems = currentQuote.items
      .filter(({ sequenceNumber, documentNumber }) =>
        documentNumber ? true : !sequenceNumbers.includes(sequenceNumber)
      )
      .map((item) => {
        return sequenceNumbers.includes(item.sequenceNumber)
          ? {
              ...item,
              status: ItemStatus.REMOVED,
            }
          : item;
      });

    setCurrentQuoteState({
      ...currentQuote,
      items: newLineItems,
      total: getTotalPrice(newLineItems),
    });
  };

  const removeGroup = (removedGroup: number) => {
    const newLineItems = currentQuote.items
      .filter(({ groupNumber, documentNumber }) =>
        documentNumber ? true : groupNumber !== removedGroup
      )
      .map((item) => {
        return {
          ...item,
          status: removedGroup === item.groupNumber ? ItemStatus.REMOVED : item.status,
        };
      });

    const newQuoteGroups = currentQuote.quoteGroups.filter(({ id }) => id !== removedGroup);

    setCurrentQuoteState({
      ...currentQuote,
      items: newLineItems,
      quoteGroups: newQuoteGroups,
      total: getTotalPrice(newLineItems),
    });
  };

  const cloneQuoteGroup = (clonedGroup: { groupName: string; items: LineItem[] }) => {
    let newGroupId = maxBy(currentQuote.quoteGroups, 'id')?.id;
    newGroupId = newGroupId ? newGroupId + 1 : 1;
    const newGroupName = clonedGroup.groupName + ' Clone';
    const newClonedItems: LineItem[] = clonedGroup.items.map((item, index) => {
      return {
        ...item,
        documentNumber: undefined,
        status: ItemStatus.CREATE,
        groupName: newGroupName,
        sequenceNumber: currentQuote.items.length + 1 + index,
        groupNumber: newGroupId,
      } as LineItem;
    });

    const newQuoteGroups = [{ name: newGroupName, id: newGroupId }, ...currentQuote.quoteGroups];

    const newLineItems = [...currentQuote.items, ...newClonedItems];
    setCurrentQuoteState({
      ...currentQuote,
      items: newLineItems,
      quoteGroups: newQuoteGroups,
      total: getTotalPrice(newLineItems),
    });
  };

  const updateGroupName = (quoteGroupId: number, newName: string) => {
    const newQuoteGroups = currentQuote.quoteGroups.map((group) => {
      return {
        ...group,
        name: group.id === quoteGroupId ? newName : group.name,
      };
    });

    const newLineItems = currentQuote.items.map((item) => {
      const groupNameChanged = item.groupNumber === quoteGroupId;

      return {
        ...item,
        groupName: groupNameChanged ? newName : item.groupName,
        status: item.documentNumber && groupNameChanged ? ItemStatus.MODIFIED : item.status,
      };
    });

    setCurrentQuoteState({
      ...currentQuote,
      items: newLineItems,
      quoteGroups: newQuoteGroups,
    });
  };

  const reorderGroups = (groupOrder: QuoteGroup[]) => {
    const newQuoteGroups = groupOrder
      .filter((group) => group.id !== UNASSIGNED_GROUP_ID)
      .map((group) => {
        return { ...group };
      });

    setCurrentQuoteState({
      ...currentQuote,
      quoteGroups: [...newQuoteGroups, { name: UNASSIGNED_GROUP_LABEL, id: UNASSIGNED_GROUP_ID }],
    });
  };

  const addQuoteTag = (newTag: string) => {
    if (currentQuote.tags.includes(newTag)) return;

    const newTags = [...currentQuote.tags, newTag];

    setCurrentQuoteState({
      ...currentQuote,
      tags: newTags,
    });
  };

  const removeTag = (tagToRemove: string) => {
    const newTags = currentQuote.tags.filter((tag) => tag !== tagToRemove);

    setCurrentQuoteState({
      ...currentQuote,
      tags: newTags,
    });
  };

  const updateSelectedShipToCountry = (newShipToCountry: string) => {
    setCurrentQuoteState({
      ...currentQuote,
      shippingAddress: { ...currentQuote.shippingAddress, country: newShipToCountry },
    });

    const newCheckoutData = { ...getSavedCheckoutData(currentQuote.transactionId) };

    newCheckoutData.shippingAddress = {
      address1: '',
      address2: '',
      addressId: '',
      city: '',
      country: newShipToCountry,
      id: '',
      isFavorite: false,
      name: '',
      state: '',
      zipcode: '',
    };

    saveCheckoutDataInLocalStorage(newCheckoutData);
  };

  return {
    createFirstQuote,
    createNewQuote,
    addGroup,
    addItem,
    removeItem,
    removeItems,
    updateQuoteItems,
    removeGroup,
    cloneQuoteGroup,
    updateGroupName,
    reorderGroups,
    getTotalPrice,
    getQuoteTotalWithExtendedWarrantyPriceLabel,
    getQuoteTotalWithoutExtendedWarrantyPriceLabel,
    updateQuoteDescription,
    updateQuoteName,
    addQuoteTag,
    removeTag,
    updateSelectedShipToCountry,
    currentQuoteCurrency: currentQuote.currencyCode,
    selectedShipToCountry: currentQuote.shippingAddress.country,
  };
}
