import { useState, useEffect, useCallback } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useLazyQuery } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import { Container, Flex } from '@Calix-Commerce/design-system/layout';
import { loadingState, currentQuoteState, currentQuoteValidationState } from 'state/atoms';
import {
  useAddressController,
  useAppNavigation,
  useNotifyError,
  useQuoteController,
} from 'utils/hooks';
import { getSearchUrlParams } from './helpers';
import { Search, SearchResults } from './subcomponents';
import { QuoteDescription, QuoteName, ShipToCountryDisplay } from 'components';
import { Title } from './styledComponents';
import { RECENTLY_ORDERED_PARTS, SEARCH_PARTS_BY_TERM } from 'connectors/queries/partSearch';
import { PartSearchResponse } from 'types/state/Part';
import { storageSelectedAccountSelector } from 'state/selectors';
import { ButtonRounded } from '@Calix-Commerce/design-system';
import { UpdateShipToCountryModal } from 'components/modals/UpdateShipToCountryModal';

const PartSearch = () => {
  const location = useLocation();
  const { redirectToQuoteDetails, redirectToQuoteDetailsForNew } = useAppNavigation();
  const selectedAccount = useRecoilValue(storageSelectedAccountSelector) || {};
  const validationState = useRecoilValue(currentQuoteValidationState);
  const currentQuote = useRecoilValue(currentQuoteState);
  const { transactionId, quoteName, quoteDescription, isOrdered, currencyCode } =
    useRecoilValue(currentQuoteState);
  const [hasSearched, setHasSearched] = useState(false);
  const [endOfResults, setEndOfResults] = useState(false);
  const [searchResults, setSearchResults] = useState<PartSearchResponse[]>([]);
  const [recentParts, setRecentParts] = useState<PartSearchResponse[]>([]);
  const [selectedShipToCountryModal, setSelectedShipToCountryModal] = useState(false);
  const { favoriteShipToCountries } = useAddressController();

  const {
    updateQuoteDescription,
    updateQuoteName,
    updateSelectedShipToCountry,
    selectedShipToCountry,
  } = useQuoteController();

  const readonly = !validationState.isWritable || isOrdered;

  // Loaders
  const [isGlobalLoading, setIsGlobalLoading] = useRecoilState(loadingState);
  const [searchForParts, { loading: searchLoading }] = useLazyQuery(SEARCH_PARTS_BY_TERM, {
    errorPolicy: 'all',
  });
  const [searchForRecentParts] = useLazyQuery(RECENTLY_ORDERED_PARTS);

  const { term } = getSearchUrlParams(location.search);

  const notifyError = useNotifyError({ fallbackMessage: 'Unable to complete search.' });

  const redirectToQuoteDetailsHandler = useCallback(() => {
    if (transactionId) {
      redirectToQuoteDetails(transactionId);
    } else {
      redirectToQuoteDetailsForNew();
    }
  }, [redirectToQuoteDetails, redirectToQuoteDetailsForNew, transactionId]);

  const runRecentlyOrderedPartSearch = () => {
    searchForRecentParts({
      variables: {
        accountId: selectedAccount.accountId,
        recentlyOrderedItemsLimit: 20,
        recentlyOrderedItemsOffset: 0,
      },
      onCompleted: (data) => {
        const partNumbers: string[] = data?.getAccount?.listRecentlyOrderedItems || [];

        if (!partNumbers.length) {
          return;
        }

        runRecentPartSearch({ partNumbers });
      },
    });
  };

  const runRecentPartSearch = ({ partNumbers }: { partNumbers: string[] }) => {
    if (!selectedShipToCountry) {
      return null;
    }

    searchForParts({
      variables: {
        input: {
          requestedAccountId: selectedAccount.accountId,
          partNumbers,
          currencyCode,
          shippingCountry: selectedShipToCountry,
        },
      },
      onCompleted: (data) => {
        const recentParts: PartSearchResponse[] = data?.searchPartsByTerm || [];

        if (recentParts.length) {
          setRecentParts(recentParts.slice(0, 10));
        }
      },
    });
  };

  const runPartSearch = ({ offset = 0 }: { offset?: number }) => {
    if (!term || !selectedShipToCountry) {
      setIsGlobalLoading(false);
      return null;
    }

    setIsGlobalLoading(true);

    searchForParts({
      variables: {
        input: {
          requestedAccountId: selectedAccount.accountId,
          term,
          currencyCode,
          offset,
          shippingCountry: selectedShipToCountry,
        },
      },
    }).then(({ data, error }) => {
      if (error) {
        notifyError(error);
      }

      const newParts: PartSearchResponse[] = data?.searchPartsByTerm || [];

      if (!newParts.length) {
        setIsGlobalLoading(false);
        setHasSearched(true);
        setEndOfResults(true);
        return;
      }

      setEndOfResults(newParts.length < 20);

      offset ? setSearchResults([...searchResults, ...newParts]) : setSearchResults([...newParts]);

      setHasSearched(true);
      setIsGlobalLoading(false);
    });
  };

  const onUpdateShipToCountry = (newShipToCountry: string) => {
    updateSelectedShipToCountry(newShipToCountry);
  };

  // Run search anytime the url search string changes
  useEffect(() => {
    if (!selectedAccount.accountId) {
      return;
    }

    setSearchResults([]);
    runPartSearch({});

    if (!hasSearched && !term) {
      runRecentlyOrderedPartSearch();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, selectedAccount.accountId, selectedShipToCountry]);

  const isBackgroundLoading = isGlobalLoading || searchLoading;

  return (
    <>
      <UpdateShipToCountryModal
        isOpen={selectedShipToCountryModal || !selectedShipToCountry}
        shipToCountry={selectedShipToCountry || ''}
        onConfirmation={onUpdateShipToCountry}
        options={favoriteShipToCountries}
        onClose={() => setSelectedShipToCountryModal(false)}
        enforceAction={!selectedShipToCountry}
      />
      <Container>
        <Flex alignment="flex-start" spacing="space-between">
          <Title>Add Parts for Quote</Title>
          <ButtonRounded onClick={redirectToQuoteDetailsHandler}>VIEW QUOTE DETAILS</ButtonRounded>
        </Flex>
        <Flex direction="column" style={{ marginBottom: '20px' }}>
          <QuoteName
            placeholder={'Name'}
            quoteName={quoteName}
            onEdit={updateQuoteName}
            readonly={readonly}
          />
          <QuoteDescription
            quoteDescription={quoteDescription}
            onEdit={updateQuoteDescription}
            placeholder={'Description'}
            readonly={readonly}
          />
          <ShipToCountryDisplay
            readonly={readonly}
            shipToCountry={currentQuote.shippingAddress.country || 'None Selected'}
            onClick={() => setSelectedShipToCountryModal(true)}
          />
        </Flex>
        <Search />
        <Flex direction="column">
          <SearchResults
            products={!hasSearched ? recentParts : searchResults}
            hasSearched={hasSearched}
            isBackgroundLoading={isBackgroundLoading}
            onEndOfPage={() => {
              if (!isBackgroundLoading && !endOfResults) {
                runPartSearch({ offset: searchResults.length });
                return;
              }
            }}
          />
        </Flex>
      </Container>
    </>
  );
};

export { PartSearch };
