import { getI18NText } from 'I18N';
import { AddressForm } from 'components/forms/address/AddressForm';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { authenticationState, checkoutAddressesState, loggedInAccountState } from 'state/atoms';
import { useAddressController } from 'utils/hooks/useAddress';
import { AddUpdateBillingAddress } from './AddUpdateBillingAddress';
import {
  BillingContainer,
  BillingDropdownInput,
  BillingFormInputLabel,
  InputTextWarning,
  Item,
  ItemRow,
  TextArea,
} from './styledComponents';
import { Address } from 'types';
import { FormRoundedButton } from 'components/Common/styledComponents';
import { isSfIdMatching } from 'utils/helpers/salesforce';

type TextAreaType = {
  label: string;
  required?: boolean;
  error?: string;
  textareaProps?: any;
};

export const BillingTextArea = ({ label, required, error, textareaProps = {} }: TextAreaType) => {
  return (
    <ItemRow>
      {error && <InputTextWarning>{error}</InputTextWarning>}
      <Item>
        <BillingFormInputLabel label={label} required={required}></BillingFormInputLabel>
        <TextArea {...textareaProps}></TextArea>
      </Item>
    </ItemRow>
  );
};

export const BillingInfoForm = ({
  address,
  onAddressChange,
  leasingAgent,
  onLeasingAgentChange,
  errors,
  setErrors,
}) => {
  const checkoutAddresses = useRecoilValue(checkoutAddressesState);
  const { accountId } = useRecoilValue(authenticationState);
  const { businessCountry } = useRecoilValue(loggedInAccountState);
  const [billingAddresses, setBillingAddresses] = useState([]);
  const [showBillingModal, setShowBillingModal] = useState(false);

  const handleAddressChange = (address: Address) => {
    onAddressChange(address);
  };

  useAddressController();

  const handleBillToAddressChange = (selectedAddress) => {
    const addressId = selectedAddress.id;

    if (addressId === 'billToAddress') {
      onAddressChange({
        addressId: addressId,
        country: businessCountry,
      });
    } else {
      const newAddress:
        | {
            address: Address;
            name: string;
          }
        | undefined = checkoutAddresses.find(
        (checkoutAddress) => checkoutAddress.addressId === addressId
      );

      if (newAddress) {
        onAddressChange({
          ...newAddress.address,
          addressId: addressId,
          name: newAddress.name,
        });
      }

      setErrors({
        ...errors,
        address: '',
        city: '',
        state: '',
        zip: '',
        country: '',
      });
    }
  };

  const handleLeasingAgentChange = (newLeasingAgent) => {
    onLeasingAgentChange(newLeasingAgent.id);
  };

  useEffect(() => {
    setBillingAddresses(
      parseBillingAddresses(
        checkoutAddresses.filter(
          (billAddress) =>
            ['billTo', 'both'].includes(billAddress.type) &&
            isSfIdMatching(billAddress.accountId, accountId)
        )
      )
    );
  }, [checkoutAddresses.length]);

  return (
    <BillingContainer>
      {showBillingModal && (
        <AddUpdateBillingAddress
          setShowBillingModal={(show: boolean) => {
            setShowBillingModal(show);
          }}
          billingAddresses={billingAddresses}
          checkoutAddresses={checkoutAddresses}
        />
      )}
      <BillingDropdownInput
        dropdownProps={{
          className: 'bill-to-address-select',
        }}
        label={getI18NText('BILL_TO_ADDRESS')}
        options={[
          { id: 'billToAddress', text: getI18NText('BILL_TO_ADDRESS') },
          ...billingAddresses,
        ]}
        selected={(address && address.addressId) || 'billToAddress'}
        handleSelectOption={handleBillToAddressChange}
      ></BillingDropdownInput>

      <AddressForm
        showFavoriteAdressSelect={false}
        address={address}
        handleAddressChange={handleAddressChange}
        isReadOnly={true}
        errors={errors}
        setError={setErrors}
      ></AddressForm>
      <BillingDropdownInput
        label={getI18NText('LEASING_AGENT')}
        options={[
          { id: true, text: getI18NText('YES') },
          { id: false, text: getI18NText('NO') },
        ]}
        selected={leasingAgent}
        handleSelectOption={handleLeasingAgentChange}
        dropdownProps={{
          showFilter: false,
        }}
      ></BillingDropdownInput>

      <FormRoundedButton
        onClick={() => {
          setShowBillingModal(true);
        }}
      >
        {getI18NText('REQUEST_BILL_TO_ADDRESS_UPDATE')}
      </FormRoundedButton>
    </BillingContainer>
  );
};

const parseBillingAddresses = (addresses) => {
  return addresses.map((billAddress) => {
    const { address, addressId } = billAddress;
    return {
      id: addressId,
      text: `${address.address1}, ${address.city}, ${address.state}, ${address.zipcode}`,
    };
  });
};
