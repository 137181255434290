import { useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useParams } from 'react-router-dom';

import { currentQuoteValidationState, loadingState } from 'state/atoms';
import { useAppNavigation, useQuoteActionController } from 'utils/hooks';
import { FETCH_POLICY } from 'utils/constants';
import { storageCurrentQuoteSelector } from 'state/selectors';

const withQuoteLoad = (
  Page: React.ComponentType,
  ignoreUnsavedChanges = true,
  fetchPolicy = FETCH_POLICY.CACHE_FIRST
) => {
  const WrapperComponent = (props) => {
    const fetchPolicyForLoad = props?.fetchPolicy || fetchPolicy;
    const { redirectToQuoteList } = useAppNavigation();
    const setLoading = useSetRecoilState(loadingState);
    const [isLoadingQuote, setIsLoadingQuote] = useState(true);

    const { loadQuote } = useQuoteActionController();
    const { transactionId: transactionIdFromCart } = useRecoilValue(storageCurrentQuoteSelector);
    const { isModified, hasUnavailableItems } = useRecoilValue(currentQuoteValidationState);
    const { transactionId } = useParams();

    useEffect(() => {
      const hasExistingUnsavedQuoteData = isModified && !ignoreUnsavedChanges;
      const isLoadingTheSameQuote = transactionIdFromCart === transactionId;

      if (transactionId && (!hasExistingUnsavedQuoteData || isLoadingTheSameQuote)) {
        setIsLoadingQuote(true);
        loadQuote({
          transactionId,
          overwriteLocalQuote: ignoreUnsavedChanges,
          fetchPolicy: fetchPolicyForLoad,
          refreshProductData: hasUnavailableItems,
        })
          .then(() => {
            setIsLoadingQuote(false);
          })
          .catch(() => {
            setIsLoadingQuote(false);
            //TODO: Add error message about quote load
            redirectToQuoteList();
          });
      } else {
        setIsLoadingQuote(false);
      }
    }, [transactionId]);

    useEffect(() => {
      setLoading(isLoadingQuote);
    }, [isLoadingQuote, setLoading]);

    return isLoadingQuote ? null : <Page {...props} />;
  };

  return WrapperComponent;
};

export { withQuoteLoad };
