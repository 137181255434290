import { Flex } from '@Calix-Commerce/design-system';
import styled from '@emotion/styled';

export const ShipToCountryDisplayStyled = styled(Flex)<{ readonly: boolean }>`
  position: relative;
  width: fit-content;
  cursor: ${({ readonly }) => (readonly ? 'normal' : 'pointer')};
`;

export const ShipCountryStyled = styled.span`
  width: fit-content;
  font-size: 14px;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  padding: 6px 50px 6px 0;
  color: #555;

  span {
    font-weight: bold;
  }
`;

export const IconWrapper = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  justify-content: flex-end;

  svg {
    color: #0b38db;
  }
`;
