import { Container } from '@Calix-Commerce/design-system/layout';
import { getI18NText } from 'I18N/I18N';
import { CheckoutFooter } from 'components/Footer';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  checkoutAddressesState,
  currentQuoteState,
  loadingState,
  notificationState,
} from 'state/atoms';
import {
  readCheckoutSavedData,
  saveCheckoutDataInLocalStorage,
  validFavAddressDetails,
} from 'utils/helpers/checkout';
import { useAppNavigation, useQuoteActionController, useQuoteController } from 'utils/hooks';
import { useAddressController } from 'utils/hooks/useAddress/useAddress';
import { validateCheckoutShipping } from './CheckoutShippingValidations';
import { CheckoutModal } from './styledComponents';
import { AdditionalShippingInfo } from './subcomponents';
import { AdditionalShippingContactInfo } from './subcomponents/AdditionalShippingContactInfo';
import { AddressForm } from 'components/forms/address/AddressForm';
import { Address, CheckoutAddress } from 'types';
import { CheckoutStepper } from 'components/stepper';
import { withQuoteLoad } from 'hocs';
import { withCheckoutPermissions } from 'hocs/withCheckoutPermissions';
import { withCheckoutWarrantyConfigured } from 'hocs/withCheckoutWarrantyConfigured';
import { FormRoundedButton } from 'components/Common/styledComponents';
import { CURRENT_CHECKOUT_STEP } from 'utils/constants/checkout';
import { CheckoutTitle } from 'components/Checkout';
import { storageSelectedAccountSelector } from 'state/selectors';
import { isSfIdMatching } from 'utils/helpers/salesforce';
import { withCheckoutAccessible } from 'hocs/withCheckoutAccessible';
import { UpdateShipToCountryModal } from 'components';

const CheckoutShippingPage = () => {
  const { transactionId } = useParams();
  const { accountId } = useRecoilValue(storageSelectedAccountSelector);
  const savedCheckoutData = readCheckoutSavedData(transactionId);
  const { redirectToCheckoutWarranty, redirectToCheckoutBilling, redirectToQuoteDetails } =
    useAppNavigation();
  const { refreshQuote } = useQuoteActionController();
  const setIsGlobalLoading = useSetRecoilState(loadingState);
  const { updateSelectedShipToCountry, selectedShipToCountry } = useQuoteController();
  const checkoutAddresses = useRecoilValue(checkoutAddressesState);
  const [checkoutShippingData, setCheckoutShippingData] = useState(savedCheckoutData);
  const [checkoutShippingAddress, setCheckoutShippingAddress] = useState(
    savedCheckoutData.shippingAddress
  );
  const setCheckoutAddressList = useSetRecoilState(checkoutAddressesState);
  const setNotification = useSetRecoilState(notificationState);
  const currentQuoteData = useRecoilValue(currentQuoteState);
  const setCurrentQuoteState = useSetRecoilState(currentQuoteState);
  const { deleteAddressRequest, createAddressRequest } = useAddressController();
  const [favorites, setFavorites] = useState<CheckoutAddress[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedShipToCountryModal, setSelectedShipToCountryModal] = useState(false);
  const [shipDataToPersist, setShipDataToPersist] = useState({});
  const [previousShipToAddress, setPreviousShipToAddress] = useState<Address | null>(null);
  const [headerNavigationRedirection, setHeaderNavigationRedirection] = useState<{
    redirect: () => void;
  }>();
  const [errors, setErrors] = useState({
    address: '',
    city: '',
    state: '',
    zip: '',
    country: '',
    firstName: '',
    lastName: '',
    email: '',
    confirmEmail: '',
    phoneNumber: {
      countryCode: '',
      areaCode: '',
      number: '',
      extension: '',
    },
  });
  const isWarrantyPageApplicable = currentQuoteData.items.some(
    (item) => item.extendedWarrantyEligible
  );

  const saveShippingDataOnCurrentQuote = useCallback(() => {
    setCurrentQuoteState({
      ...currentQuoteData,
      shippingAddress: checkoutShippingAddress || ({} as Address),
      shippingContactPhone: {
        countryCode: checkoutShippingData.shippingContact?.phoneNumber?.countryCode || null,
        areaCode: checkoutShippingData.shippingContact?.phoneNumber?.areaCode || null,
        number: checkoutShippingData.shippingContact?.phoneNumber?.number || null,
        extension: checkoutShippingData.shippingContact?.phoneNumber?.extension || null,
      },
      shippingContactEmail: checkoutShippingData.shippingContact?.email || null,
      shippingContactLastName: checkoutShippingData.shippingContact?.lastName || null,
      shippingContactFirstName: checkoutShippingData.shippingContact?.firstName || null,
      shipmentSchedule: checkoutShippingData.shippingInfo?.shipmentSchedule,
      packingInstructions: checkoutShippingData.shippingInfo?.packingInstructions || null,
      shippingInstructions: checkoutShippingData.shippingInfo?.shippingInstructions || null,
    });
  }, [checkoutShippingData, currentQuoteData, setCurrentQuoteState]);

  const favoriteChangeHandler = (favorite) => {
    if (favorite.id === 'fav') {
      setCheckoutShippingAddress({
        address1: '',
        address2: '',
        state: '',
        city: '',
        zipcode: '',
        country: checkoutShippingAddress?.country,
      } as Address);
    } else {
      const favoriteAddress = parseFavoriteAddress(
        favorites.filter((fav) => fav.addressId === favorite.id)[0]
      );
      const countryChanged = favoriteAddress.country !== checkoutShippingAddress?.country;

      if (countryChanged) {
        setSelectedShipToCountryModal(true);

        setPreviousShipToAddress({
          addressId: checkoutShippingAddress?.addressId || 'fav',
          address1: checkoutShippingAddress?.address1 || '',
          address2: checkoutShippingAddress?.address2 || '',
          state: checkoutShippingAddress?.state || '',
          city: checkoutShippingAddress?.city || '',
          zipcode: checkoutShippingAddress?.zipcode || '',
          country: checkoutShippingAddress?.country,
        } as Address);
      }

      setCheckoutShippingAddress({
        ...favoriteAddress,
        addressId: favorite.id,
      });
    }
  };

  const deleteAddress = (favorite) => {
    const [deleteAddressMutation] = deleteAddressRequest;

    deleteAddressMutation({
      variables: {
        addressId: favorite,
      },
      onCompleted: () => {
        setCheckoutAddressList(
          checkoutAddresses.filter(
            (addressItem: CheckoutAddress) => addressItem.addressId !== favorite
          )
        );
      },
    });
  };

  const handleAddressChange = (newAddress) => {
    delete newAddress.id;

    const countryChanged = newAddress.country !== selectedShipToCountry;

    if (countryChanged) {
      setPreviousShipToAddress({
        addressId: checkoutShippingAddress?.addressId || 'fav',
        address1: checkoutShippingAddress?.address1 || '',
        address2: checkoutShippingAddress?.address2 || '',
        state: checkoutShippingAddress?.state || '',
        city: checkoutShippingAddress?.city || '',
        zipcode: checkoutShippingAddress?.zipcode || '',
        country: selectedShipToCountry,
      } as Address);
      setSelectedShipToCountryModal(true);
    }

    setCheckoutShippingAddress({
      ...newAddress,
      name: '',
      isFavorite: false,
      addressId: 'fav',
    });
  };

  const handleAddFavorite = () => {
    if (checkoutShippingAddress) {
      createAddressRequest(checkoutShippingAddress, (newFavorite) => {
        setCheckoutAddressList([...newFavorite.createAddress, ...checkoutAddresses]);
      });
    }
  };

  const handleUpdateShipToCountry = async (newCountry) => {
    setSelectedShipToCountryModal(false);
    updateSelectedShipToCountry(newCountry);

    if (!transactionId) {
      return;
    }

    setIsGlobalLoading(true);
    await refreshQuote({
      transactionId,
      newShipToCountry: newCountry,
      refreshProductData: true,
    }).then((data) => {
      const unavailableItems = data.items.some(({ available }) => !available);
      if (unavailableItems) {
        redirectToQuoteDetails(transactionId);
      }
    });
    setIsGlobalLoading(false);
  };

  const handleDismissShipToCountry = () => {
    setCheckoutShippingAddress({
      ...previousShipToAddress,
    } as Address);

    setPreviousShipToAddress(null);

    setSelectedShipToCountryModal(false);
  };

  const shippingData = checkoutShippingData.shippingInfo;
  const contactData = checkoutShippingData.shippingContact;

  const nextNavigationValidation = async (navigationRedirectFn) => {
    const shippingAddress = {
      ...(checkoutShippingAddress ?? {}),
    };
    const shippingInfo = {
      packingInstructions: shippingData?.packingInstructions,
      shippingInstructions: shippingData?.shippingInstructions,
      shipmentSchedule: shippingData?.shipmentSchedule || [],
    };
    const shippingContact = {
      firstName: contactData?.firstName,
      lastName: contactData?.lastName,
      phoneCountry: contactData?.phoneNumber?.countryCode,
      phoneArea: contactData?.phoneNumber?.areaCode,
      phoneNumber: contactData?.phoneNumber?.number,
      email: contactData?.email,
      emailConfirm: contactData?.confirmEmail,
      phoneExtension: contactData?.phoneNumber?.extension,
      phone: {
        area: contactData?.phoneNumber?.areaCode,
        country: contactData?.phoneNumber?.countryCode,
        number: contactData?.phoneNumber?.number,
        extension: contactData?.phoneNumber?.extension,
      },
    };

    const { isAllDataValid, shippingErrors, focusOnElement, isShipInstructionsMissing } =
      validateCheckoutShipping({
        shippingAddress,
        shippingInfo,
        shippingContact,
      });

    if (isAllDataValid) {
      const localStorageDataSet = {
        shippingAddress,
        shippingInfo,
        shippingContact,
        transactionId,
      };

      if (isShipInstructionsMissing) {
        setShipDataToPersist(localStorageDataSet);
        setShowModal(true);
      } else {
        saveCheckoutDataInLocalStorage(localStorageDataSet);
        saveShippingDataOnCurrentQuote();
      }
    } else {
      setErrors(shippingErrors);
      setNotification({
        text: getI18NText('EMPTY_FIELDS_ERROR'),
        show: true,
        type: 'error',
        timeout: null,
      });
    }

    if (focusOnElement) {
      setTimeout(() => {
        const focusEl = document.getElementById(focusOnElement);

        focusEl &&
          focusEl.scrollIntoView({
            behavior: 'smooth',
          });
      }, 0);
    }

    if (!isAllDataValid || isShipInstructionsMissing) {
      setHeaderNavigationRedirection({
        redirect: navigationRedirectFn,
      });

      return Promise.reject('Shipping validations fails');
    }

    return Promise.resolve();
  };

  const backNavigationConfig = {
    navigate: async () =>
      isWarrantyPageApplicable ? redirectToCheckoutWarranty() : redirectToQuoteDetails(),
  };
  const nextNavigationConfig = {
    navigate: async () => redirectToCheckoutBilling(),
    validate: nextNavigationValidation,
  };

  const enableAddToFavorite = validFavAddressDetails(checkoutShippingAddress || {}, favorites);

  useEffect(() => {
    setFavorites(
      checkoutAddresses.filter(
        (address) =>
          address.favorite &&
          ['shipTo', 'both'].includes(address.type) &&
          isSfIdMatching(address.accountId, accountId)
      )
    );
  }, [checkoutAddresses]);

  useEffect(() => {
    if (!checkoutShippingAddress?.country) {
      setCheckoutShippingAddress({
        ...checkoutShippingAddress,
        country: currentQuoteData.shippingAddress.country,
      } as Address);
    }
  }, []);

  return (
    <Container>
      <CheckoutStepper
        currentStep={CURRENT_CHECKOUT_STEP.SHIPPING}
        validate={nextNavigationValidation}
      ></CheckoutStepper>
      <CheckoutModal
        isOpen={showModal}
        position={'center'}
        title={getI18NText('SHIPPING_WITHOUT_INSTRUCTION_HEADER')}
        confirmButtonText={getI18NText('CONFIRM')}
        // @ts-expect-error We expect a formatted information
        content={
          <p>
            {getI18NText('SHIPPING_WITHOUT_INSTRUCTION_1')}
            <br></br>
            <br></br>
            {getI18NText('SHIPPING_WITHOUT_INSTRUCTION_2')}
            <br></br>
            <br></br>
            {getI18NText('SHIPPING_WITHOUT_INSTRUCTION_3')}
          </p>
        }
        flag="Warning"
        onConfirmation={() => {
          setShowModal(false);
          saveCheckoutDataInLocalStorage(shipDataToPersist);
          saveShippingDataOnCurrentQuote();

          if (headerNavigationRedirection && headerNavigationRedirection.redirect) {
            headerNavigationRedirection.redirect();
          } else {
            redirectToCheckoutBilling();
          }
        }}
        onCancel={() => {
          setHeaderNavigationRedirection(undefined);
          setShowModal(false);
        }}
      ></CheckoutModal>
      <UpdateShipToCountryModal
        isOpen={selectedShipToCountryModal}
        shipToCountry={checkoutShippingAddress?.country || 'Country'}
        onConfirmation={handleUpdateShipToCountry}
        onClose={handleDismissShipToCountry}
      />
      <CheckoutTitle id="shipping-page-header">{getI18NText('SHIPPING_INFO')}</CheckoutTitle>
      <AddressForm
        favoriteAddresses={parseFavorites(favorites)}
        handleSelectFavorite={favoriteChangeHandler}
        address={checkoutShippingAddress}
        handleRemoveFavorite={deleteAddress}
        handleAddressChange={handleAddressChange}
        errors={errors}
        setError={setErrors}
      ></AddressForm>
      <FormRoundedButton
        style={
          enableAddToFavorite
            ? {}
            : { cursor: 'not-allowed', pointerEvents: 'none', opacity: '.65' }
        }
        onClick={handleAddFavorite}
      >
        {getI18NText('ADD_TO_FAVORITES')}
      </FormRoundedButton>
      <CheckoutTitle id="additional-shipping-info-header">
        {getI18NText('ADDITIONAL_SHIPPING_INFO')}
      </CheckoutTitle>
      <AdditionalShippingInfo
        shipments={shippingData?.shipmentSchedule}
        onShipmentChange={(newShipments) => {
          setCheckoutShippingData({
            ...checkoutShippingData,
            //@ts-ignore
            shippingInfo: {
              ...shippingData,
              shipmentSchedule: newShipments,
            },
          });
        }}
        packagingInstruction={shippingData?.packingInstructions}
        shippingInstruction={shippingData?.shippingInstructions}
        onPackagingInstructionChange={(newInstructions) => {
          setCheckoutShippingData({
            ...checkoutShippingData,
            //@ts-ignore
            shippingInfo: {
              ...shippingData,
              packingInstructions: newInstructions,
            },
          });
        }}
        onShippingInstructionChange={(newInstructions) => {
          setCheckoutShippingData({
            ...checkoutShippingData,
            //@ts-ignore
            shippingInfo: {
              ...shippingData,
              shippingInstructions: newInstructions,
            },
          });
        }}
      ></AdditionalShippingInfo>
      <CheckoutTitle id="additional-shipping-contact-info-header">
        {getI18NText('ADDITIONAL_SHIPPING_CONTACT_INFO')}
      </CheckoutTitle>
      <AdditionalShippingContactInfo
        contactInfo={contactData}
        onContactInfoChange={(newContactInfo) => {
          setCheckoutShippingData({
            ...checkoutShippingData,
            shippingContact: newContactInfo,
          });
        }}
        errors={errors}
        setError={setErrors}
      ></AdditionalShippingContactInfo>
      <CheckoutFooter
        backNavigationConfig={backNavigationConfig}
        nextNavigationConfig={nextNavigationConfig}
      />
    </Container>
  );
};

export const parseFavorites = (favorites) => {
  return favorites.map((favorite) => {
    const { address, addressId } = favorite;
    return {
      id: addressId,
      text: `${address.address1}, ${address.city}, ${address.state}, ${address.zipcode}`,
    };
  });
};

export const parseFavoriteAddress = (favorite) => {
  const address = favorite ? favorite.address : {};

  return {
    id: favorite.addressId,
    name: favorite.name,
    isFavorite: true,
    address1: address.address1 || '',
    address2: address.address2 || '',
    city: address.city || '',
    country: address.country || '',
    state: address.state || '',
    zipcode: address.zipcode || '',
  };
};

export const CheckoutShipping = withCheckoutPermissions(
  withQuoteLoad(withCheckoutAccessible(withCheckoutWarrantyConfigured(CheckoutShippingPage)))
);
