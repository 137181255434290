import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { ShipToCountryDisplayStyled, ShipCountryStyled, IconWrapper } from './styledComponents';

const ShipToCountryDisplay = ({ shipToCountry, onClick, readonly }) => {
  return (
    <ShipToCountryDisplayStyled
      alignment="center"
      readonly={readonly}
      onClick={() => (!readonly ? onClick() : null)}
    >
      <ShipCountryStyled>
        Country: <span>{shipToCountry}</span>
      </ShipCountryStyled>
      {!readonly ? (
        <IconWrapper>
          <EditOutlinedIcon />
        </IconWrapper>
      ) : null}
    </ShipToCountryDisplayStyled>
  );
};

export { ShipToCountryDisplay };
