import { QueryResult, useMutation, useQuery } from '@apollo/client';
import { getI18NText } from 'I18N';
import { CREATE_ADDRESS, DELETE_ADDRESS } from 'connectors/mutations';
import { GET_ADDRESSES } from 'connectors/queries';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  authenticationState,
  checkoutAddressesState,
  loadingState,
  notificationState,
} from 'state/atoms';
import { storageSelectedAccountSelector } from 'state/selectors';
import { isSfIdMatching } from 'utils/helpers/salesforce';
import uniqBy from 'lodash/uniqBy';
import { Address, CheckoutAddress } from 'types';

export const useAddressController = (): {
  addressRequest: QueryResult;
  deleteAddressRequest;
  createAddressRequest: (
    shippingAddress: Address,
    addFavCallback: (newAddress: { createAddress: CheckoutAddress[] }) => void
  ) => void;
  favoriteShipToAddresses: CheckoutAddress[];
  defaultShipToCountry: string | null;
  favoriteShipToCountries: string[];
} => {
  const { varAccountId } = useRecoilValue(authenticationState);
  const { accountId } = useRecoilValue(storageSelectedAccountSelector);
  const [checkoutAddresses, setCheckoutAddress] = useRecoilState(checkoutAddressesState);
  const setLoadingState = useSetRecoilState(loadingState);
  const setNotification = useSetRecoilState(notificationState);

  const addressRequest = useQuery(GET_ADDRESSES, {
    variables: { accountId, varAccountId },
    onCompleted: (response) => {
      if (response) {
        setCheckoutAddress(response.getAccount.getAddresses);
      }
    },
  });

  const deleteAddressRequest = useMutation(DELETE_ADDRESS);
  const [createAddressMutation] = useMutation(CREATE_ADDRESS);

  const createAddressRequest = async (shippingAddress, addFavCallback) => {
    setLoadingState(true);

    createAddressMutation({
      variables: {
        address: {
          accountId,
          varAccountId,
          address: {
            address1: shippingAddress.address1,
            address2: shippingAddress.address2,
            city: shippingAddress.city,
            country: shippingAddress.country,
            state: shippingAddress.state,
            zipcode: shippingAddress.zipcode,
          },
          channel: null,
          favorite: true,
        },
      },
      onCompleted: (newFavorite) => {
        addFavCallback && addFavCallback(newFavorite);
        setLoadingState(false);

        setNotification({
          text: getI18NText('FAVORITE_ADDRESS_CREATE_SUCCESS'),
          show: true,
          type: 'success',
          timeout: 4000,
        });
      },
    });
  };

  const favoriteShipToAddresses = checkoutAddresses.filter(
    (address) =>
      address.favorite &&
      ['shipTo', 'both'].includes(address.type) &&
      isSfIdMatching(address.accountId, accountId)
  );

  const uniqueShipToCountries = uniqBy(favoriteShipToAddresses, 'address.country')
    .filter((address: CheckoutAddress) => address.address.country !== null)
    .map((address: CheckoutAddress) => address.address.country as string);

  const favoriteShipToCountries = uniqueShipToCountries.length ? uniqueShipToCountries : [];

  //We want customers to choose from favorite countries is they have more than one favorite country
  const defaultShipToCountry =
    favoriteShipToCountries.length === 1 ? favoriteShipToCountries[0] : '';

  return {
    addressRequest,
    deleteAddressRequest,
    createAddressRequest,
    favoriteShipToAddresses,
    favoriteShipToCountries,
    defaultShipToCountry,
  };
};
